<template>
  <div>
    <v-snackbar
      absolute
      v-model="opensnackbar"
      bottom
      timeout="-1"
      class="fixed-dialog"
      text
      rounded="lg"
    >
      <v-list style="padding-top: 0px; border-radius: 10px 10px 0px 0px;" class="mr-n2">
        <v-toolbar
          :color="color.theme"
          dense
          short
          flat
          fab
          :style="stylesnackbar"
          :class="openlistupload === true ? '' : 'mb-n2'"
        >
          <v-list-item v-if="percentuploadedallfile !== 100 && count_cancel === 0">
            <v-list-item-content class="ml-n2 text-left">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.uploading") }}<br /><span
                  >{{
                    length_complete + " " + $t("dragdrop.of") + " " + totalall
                  }}
                  {{ $t("dragdrop.file") }}</span
                ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                @click="detailuploadclick()"
              >
                <span
                  v-if="count_uploadfail > 0"
                >
                  {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                    count_uploadfail
                  }}
                  {{ $t("dragdrop.of") }} {{ totalall }}
                  {{ $t("dragdrop.file") }}
                </span>
                <span v-else>
                  {{ $t("dragdrop.detail") }}
                </span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-content v-if="count_cancel > 0" class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.cancelupload") }}</v-list-item-title >
            </v-list-item-content>
            <v-list-item-content v-else class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.uploadsuccess") }}</v-list-item-title >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                @click="detailuploadclick()"
              >
                <span
                  v-if="count_uploadfail > 0"
                >
                  {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                    count_uploadfail
                  }}
                  {{ $t("dragdrop.of") }} {{ totalall }}
                  {{ $t("dragdrop.file") }}
                </span>
                <span v-else>
                  {{ $t("dragdrop.detail") }}
                </span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-btn
            color="white"
            @click="openlistupload = !openlistupload"
            icon
            x-small
            fab
          >
            <v-icon v-if="openlistupload === true">mdi-arrow-down-drop-circle-outline</v-icon>
            <v-icon v-else>mdi-arrow-up-drop-circle-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="openclose === true ? false : true"
            color="white"
            @click="closedetail()"
            icon
            x-small
            fab
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-if="openlistupload === true" style="max-height:250px; height:auto; width:470px; overflow-y: auto">
          <v-list-item class="mt-n1 mb-n5">
            <v-spacer></v-spacer>
            <v-list-item-action>
              <v-btn
               small
               text
               :loading="loading"
               :disabled="length_processing === showfileupload.length || count_cancel === 1"
               :color="color.theme"
               @click="(loader = 'loading'), cancel_upload()"
              >
                {{ $t("dragdrop.cancel") }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="count_success > 0">
            <v-list-item-title class="mt-1">
              <span class="pl-1">{{ $t("inputuploadfolder.uploadfoldersuccess") }} {{ count_success }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="count_uploadfail > 0">
            <v-list-item-title class="mt-1">
              <span class="pl-1">{{ $t("inputuploadfolder.uploadfail") }} {{ count_uploadfail }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="count_duplicate > 0">
            <v-list-item-title class="mt-1">
              <span class="pl-1">{{ $t("inputuploadfolder.uploaddup") }} {{ count_duplicate }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-n4" v-for="(item, i) in shower_uploading" :key="i" dense>
            <v-list-item-content>
              <v-list-item-title v-if="item.name" class="mt-1">
                <v-icon :color="item.icon[1]" style="margin-bottom:5px;">{{ item.icon[0] }}</v-icon>
                <span class="pl-1">{{ item.name }}</span>
              </v-list-item-title>
              <v-list-item-title v-else class="mt-1">
                <span class="pl-1">{{ $t("uploadfile.loadingfile") }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-center">
              <v-btn
                fab
                small
                icon
                v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                style="cursor: context-menu"
              >
                <v-progress-circular
                  :rotate="90"
                  :size="30"
                  :width="3"
                  :value="100"
                  :color="item.color"
                >
                  <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                  <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                  <v-icon v-else>mdi-exclamation</v-icon>
                </v-progress-circular>
              </v-btn>
              <v-hover v-else v-slot="{ hover }">
                <v-btn
                  fab
                  small
                  icon
                  @click="cancel_upload_one_item(item)"
                  :disabled="count_cancel === 1"
                >
                  <v-progress-circular  
                    :rotate="90"
                    :size="30"
                    :width="3"
                    :value="!item.value
                      ? 0
                      : parseInt(item.value)"
                    :color="hover ? 'error' : color.theme"
                  >
                    <span v-if="!hover">
                      {{
                        !item.value
                          ? 0
                          : parseInt(item.value)
                      }}
                    </span>
                    <v-icon
                      v-else
                      color="error"
                    >
                      mdi-close
                    </v-icon>
                  </v-progress-circular>
                </v-btn>
              </v-hover>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="showfileupload.length <= 0">
            <v-list-item-title class="mt-1">
              <span class="pl-1">{{ $t("uploadfile.loadingfile") }}</span>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-snackbar>
    <input
      type="file"
      ref="fileinput"
      style="display: none"
      mozdirectory="true"
      webkitdirectory="true"
      directory="true"
      @change="fn_onFileChange"
    />
    <detailupload
      v-if="opendetailupload === true"
      :show="opendetailupload"
      :dataupload="showfileupload"
      @hidedetailupload="hidedetail()"
      @closedetailupload="closedetail()"
      @cancel="cancel_upload()"
      @canceloneitem="cancel_upload_one_item"
      :length_processing="length_processing"
    ></detailupload>
    <dialogalertduplicatefile
      :show="openalertduplicatefile"
      :listfileduplicate="listfileduplicate"
      uploadfolder="true"
      @skip="setStatusFileDuplicate"
      @replace="setStatusFileDuplicate"
      @copy="setStatusFileDuplicate"
    ></dialogalertduplicatefile>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfsizeDataConverter from "@/globalFunctions/sizeDataConverter.js";
import { v4 as uuidv4 } from "uuid";
import axios, { CancelToken } from 'axios';

const dialogalertduplicatefile = () => import("@/components/optional/dialog-alert-duplicate-file.vue");
const detailupload = () => import("@/components/optional/dialog-detailupload");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  name: "input_uploadfile",
  props: ["enabled_inputfile", "parentfolder", "departmentid"],
  components: { dialogalertduplicatefile, detailupload },
  data: function() {
    return {
      closeSnackbar: false,
      detail_fail:true,
      percentupload:0,
      folderupload:"",
      panel_expansion: [1],
      part_per_send: 1,
      showfileupload: [],
      tempshowfileupload: [],
      folderParent: [],
      rawfileupload: [],
      opensnackbar: false,
      fulldisplay: false,
      currentupload: "",
      datauploading:{},
      loading: false,
      zerofile: true,
      count_cancel: 0,
      source: "",
      fileduplicate: "",
      listfileduplicate: [],
      openalertduplicatefile: false,
      listpathfolderduplicate: [],
      listfileinfolderduplicate: [],
      checkduplicatechunk: false,
      statusskip: false,
      checkfirstloopchoice: false,
      // checkcurrentupload: false,
      loopcheckupload: "",
      count_processing: 0,
      percentfileuploading: 0,
      opendetailupload: false,
      openlistupload: false,
      count_upload: 0,
      openclose: true,
      numid: 0,
      indexupload: 0,
      remain_storage: 0,
      stacksizefile: 0,
      awaitfolder: false,
      showupload: [],
      datachunkwaiting: [],
      chunkwaiting: [],
      checkopenchunk: false,
      conutindexupload: 0,
      checkupfolder: false
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken"
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataAccountCCTV",
      "dataRemainStorage",
    ]),
    percentuploadedallfile() {
      return (
        this.showfileupload.reduce((a, b) => {
          let x = b["value"] === 500 ? 100 : b["value"];
          return a + x;
        }, 0) / this.showfileupload.length
      );
    },   
    totalall() {
      return this.showfileupload.length;
    },
    length_uploading() {
      return (
        this.showfileupload
          .filter((item) => {
            return item.status.code === "Uploading";
          })
          .concat(
            this.chunkwaiting.filter((item) => {
              return item.status === "Uploading";
            })
          ) ||
        0
      );
    },
    // จำนวนที่ไม่ใช่ start
    length_processing() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "success" ||
            item.status.code === "duplicate" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    // จำนวนของอัปไม่สำเร็จและยกเลิก
    count_uploadfail() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    count_success() {
       return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "success"
          );
        }).length
      );
    },
    // จำนวนของอัปไม่สำเร็จและยกเลิก
    count_duplicate() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "duplicate"
          );
        }).length
      );
    },
    // จำนวนการเสร็จกระบวนการ
    length_complete() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "success" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    stylesnackbar() {
      if (this.openlistupload === true) {
        return "border-radius: 10px 10px 0px 0px; width:470px;"
      } else {
        return "border-radius: 10px 10px 10px 10px; width:470px;"
      }
    },
    // จำนวนที่ไม่ใช่ start
    shower_uploading() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "start" ||
            item.status.code === "Uploading"
          );
        }).slice(0,5)
      );
    },
  },
  watch: {
    length_processing(val) {
      // เช็คว่ามีไฟล์หรือไม่
      if (this.showfileupload.length !== 0) {
        // เช็คว่าทุกไฟล์อัปเสร็จสิ้นหรือยัง
        if (this.length_complete === this.showfileupload.length) {
          this.openclose = true;
          this.checkduplicatezone = false;
          this.indexupload = 0;
          this.count_upload = 0;
          this.selecteallduplicate = [];
          // this.clearDataSnackbarUpload();
          setTimeout(() => {
            this.zerofile = true;
            this.forceDestroyEvent(false);
            this.$emit("closewithoutload");
            this.$emit("reload");
          }, 1500);
        } else {
          // เช็คว่าอยู่ไม่ได้อยู่ในกระบวนการอัปไฟล์ซ้ำ
          if (this.checkduplicatezone === false) {
            // เช็คว่าได้ทำงานกับทุกไฟล์แล้วหรือไม่
            if (val === this.showfileupload.length) {
              // เช็คว่ามีไฟล์ซ้ำหรือไม่
              if (this.listfileduplicate.length > 0) {
                this.openalertduplicatefile = true;
                this.checkduplicatezone = true;
              }
            }
          }
        }
      }
    },
    enabled_inputfile: function(_val) {
      if (_val === true) {
        this.fn_openUploadfolder();
        //console.log("departmentid", this.departmentid);
      }
    },
    length_uploading(val) {
      if (this.opensnackbar === true) {
        //  && this.statusuploading === true
        if (this.showfileupload.length > 0) {
          if (this.showfileupload.length >= this.conutindexupload) {
            if (this.checkopenchunk === false) {
              let lastindex = this.conutindexupload;
              if (val.length < 5 && val.length >= 0) {
                if (this.showfileupload[lastindex]) {
                  if (val.length < 5) {
                    if (this.checkupfolder !== true) {
                      this.checkUploading(lastindex);
                      this.conutindexupload += 1;
                      if (this.showfileupload[lastindex]["type"] === "folder") {
                        this.checkupfolder = true;
                      }
                    }
                  }
                }
              }
            } else {
              console.log("this.checkopenchunk", this.checkopenchunk);
              let lastindex = this.datachunkwaiting.id;
              if (val.length <= 5) {
                if (
                  this.showfileupload[lastindex]["allchunk"].length >
                  this.showfileupload[lastindex]["numchunk"] + 1
                ) {
                  this.showfileupload[lastindex]["numchunk"] += 1;
                  // this.fnCheckingFileData(
                  //   this.showfileupload[lastindex].formData,
                  //   lastindex,
                  //   this.showfileupload[lastindex]["numchunk"]
                  // );
                  this.fnuploadChunks3(
                    this.showfileupload[lastindex]["datafile"],
                    lastindex,
                    this.showfileupload[lastindex]["numchunk"],
                    ""
                  );
                } else {
                  this.checkopenchunk = false;
                }
              }
            }
          } else {
            console.log("fefef 1");
            if (this.chunkwaiting.length > 0) {
              if (
                this.chunkwaiting.filter((item) => item.status === "Uploading")
                  .length > 0 ||
                this.chunkwaiting.filter((item) => item.status === "start")
                  .length > 0
              ) {
                let lastindex = this.datachunkwaiting.id;
                if (val.length <= 5) {
                  if (
                    this.showfileupload[lastindex]["allchunk"].length >
                    this.showfileupload[lastindex]["numchunk"] + 1
                  ) {
                    this.showfileupload[lastindex]["numchunk"] += 1;
                    this.fnuploadChunks3(
                      this.showfileupload[lastindex]["datafile"],
                      lastindex,
                      this.showfileupload[lastindex]["numchunk"],
                      ""
                    );
                  } else {
                    this.checkopenchunk = false;
                  }
                }
              }
            }
          }
        }
      } else {
        this.conutindexupload = 0;
      }
    },
  },
  methods: {
    closedetail() {
      this.opensnackbar = false;
      this.openclose = false;
      setTimeout(() => {
        this.opendetailupload = false;
        this.fn_endprocess();
        this.showfileupload = [];
        this.conutindexupload = 0;
        if (this.count_cancel > 0 || this.count_uploadfail > 0) {
          this.$store.dispatch("check_storage", this.dataAccountId);
        }
      }, 1500);
    },
    // ดักการรีโหลด
    forceDestroyEvent(status) {
      if (status === true) {
        window.addEventListener('beforeunload', this.removeUser, true);
      } else {
        window.removeEventListener('beforeunload', this.removeUser, true);
      }
    },

    // event
    removeUser(event) {
      event.returnValue = "Uploading folder";
    },
    // ฟังก์ชันสลับจาก รายละเอียด ไป snackbar
    hidedetail() {
      this.opendetailupload = false;
      // this.opensnackbar = true;
    },
    cancel_upload(){
      this.btnClosedetail = true
      this.count_cancel = 1
      this.closeSnackbar = true
      this.tempshowfileupload = [...this.showfileupload];
      this.rawfileupload = [];
      this.folderParent = [];
      this.currentpath = "";
      this.openclose = true;
      // this.showfileupload = [];
      this.zerofile = true;
      // this.source.cancel("cancel Upload");
      for (let i = 0; i < this.showfileupload.length; i++) {
        if (this.showfileupload[i]["status"]["code"] === "start" || this.showfileupload[i]["status"]["code"] === "Uploading") {
          if (this.showfileupload[i]["canceltoken"] !== "") {
            this.showfileupload[i]["canceltoken"].cancel();
          }
          this.showfileupload[i]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          this.showfileupload[i]["color"] = "error"
          if (this.showfileupload[i]["listxhr"].length > 0) {
            const xhrList = this.showfileupload[i]["listxhr"];
            xhrList.forEach(xhr => xhr.abort());
          }
        }
      }
      // console.log("temp dataUpload", this.tempshowfileupload)
    },
    // ฟังก์ชันยกเลิกการอัปโหลด 1 ไฟล์
    cancel_upload_one_item(item) {
      item.checkcancelone = true;
      console.log("item", item);
      if (item.canceltoken !== "") {
        item.canceltoken.cancel();
      }
      if (item["listxhr"].length > 0) {
        const xhrList = item["listxhr"];
        xhrList.forEach(xhr => xhr.abort());
      }
      item["status"] = {
        code: "cancel",
        message: "dragdrop.cancelupload",
        devMessage: "",
      };
      item["color"] = "error"
      this.stacksizefile = this.stacksizefile - item.size
      this.count_upload--
      if (item.size > 1073741824) {
        let itemchunk = this.chunkwaiting.filter(el => el.id = item.id)
        for (let i = 0; i < itemchunk.length; i++) {
          let indexwatingchunk = this.chunkwaiting.indexOf(itemchunk[i]);
          this.chunkwaiting[indexwatingchunk].status === "cancel";
          if (itemchunk.length > i + 1) {
            if (this.chunkwaiting.filter(check => check.status === "Uploading").length === 0) {
              this.checkopenchunk = false;
              this.datachunkwaiting = [];
            }
          }
        }
      }
      item["color"] = "error"
      // this.count_uploadfail++
    },
    checkbutton_expansion() {
      //let length_processing = this.length_processing()
      //let length_total = this.length_total()
          console.log("hhhhh",this.panel_expansion,this.length_processing === this.length_total);
      if (this.panel_expansion === 0) {
       
      } else if(this.panel_expansion !== 0 && this.length_processing === this.length_total){  
          this.minidisplay = false;
          this.$emit("loadfile");     
      }

      if(this.panel_expansion !== 0){
        this.detail_fail = true
      }else{
        this.detail_fail = false
      }
      //console.log(this.panel_expansion);
    },
    // STEP1: OPEN CHOOSE FOLDER
    fn_openUploadfolder() {
      this.$refs.fileinput.click();
      this.closeSnackbar = false;
      document.body.onfocus = this.fn_checkzerofile;
    },
    fn_checkzerofile() {
      setTimeout(() => {
        if (this.zerofile === true) {
          this.$emit("closewithoutload");
        }
      }, 2500);
    },
    // STEP2: GET CHOOSE FOLDER
    fn_onFileChange(_file) {
      this.zerofile = false;
      if (this.showfileupload.length < 1) {
        this.btnClosedetail = false;
        this.tempshowfileupload = [];
        this.showfileupload = []
        this.count_cancel = 0;
        this.percentupload = 0;
        this.fn_prepareuploadfolder(_file);
      } else {
        this.$emit("closewithoutload");
        Toast.fire({
          icon: "error",
          title: "ไม่สามารถเลือกไฟล์ ในขณะมีกำลังอัปโหลด"
        });
      }

      // this.$emit("openDrag");
      const input = this.$refs.fileinput;
      input.type = "text";
      input.type = "file";
    },

    // STEP3: READ FILES&FOLDER
    fn_prepareuploadfolder(_rawfolder) {
      this.forceDestroyEvent(true)
      _rawfolder.preventDefault();

      let items = _rawfolder.dataTransfer
        ? _rawfolder.dataTransfer.items || _rawfolder.dataTransfer.files
        : _rawfolder.target.files;

      for (var i = 0; i < items.length; i++) {
        var item = items[i].webkitGetAsEntry
          ? items[i].webkitGetAsEntry()
          : items[i];
        if (item) {
          this.fn_traverseFileTree(item);
        }

        // if (i === items.length - 1) {
          // this.checkUploadFiles(this.indexupload);
        // }
      }
      this.opensnackbar = true;
      // this.fn_uploadfileandfolder()
    },

    // STEP4: READ TREE FILES&FOLDER
    fn_traverseFileTree(item, path) {
      path = path || "";
      if (item.isDirectory) {
        // Get folder contents
        var dirReader = item.createReader();
        dirReader.readEntries(function(entries) {
          for (var i = 0; i < entries.length; i++) {
            this.fn_traverseFileTree(entries[i], path + item.name + "/");
          }
        });
      } else if (item.webkitRelativePath) {
        this.fn_checkpathWebkit(item.webkitRelativePath, item);
      } else if (item.isFile) {
        // Get file
        let parent = document.getElementById("files");
        item.file(function(file) {
          let div = document.createElement("div");
          let text = document.createTextNode(path + file.name);
          div.appendChild(text);
          parent.appendChild(div);
        });
      }
    },

    // STEP4.1: READ FILES&FOLDER IN WEBKIT (OPTIONAL)
    fn_checkpathWebkit(_path, _file) {
      let rawcurrentpath = _path;
      let currentpath = rawcurrentpath.split("/");
      let currentpath_withoutfile = currentpath.slice(
        0,
        currentpath.length - 1
      );
      if (this.folderParent.length === 0) {
        let folder_pathname = "";
        let folder_pathname_check = "";
        for (let i = 0; i < currentpath_withoutfile.length; i++) {
          if (i === 0) {
            folder_pathname += "/";
          } else {
            folder_pathname += currentpath_withoutfile[i-1] + "/";
          }
          if (i === 0) {
            folder_pathname_check = currentpath_withoutfile[i];
          } else {
            folder_pathname_check += "/" + currentpath_withoutfile[i];
          }
          // let indexFolderParent = this.folderParent.findIndex(
          //   r => r.folder_name === currentpath_withoutfile[i]
          // );
          this.folderParent.push({
            folder_name: currentpath_withoutfile[i],
            folder_pathname: folder_pathname,
            folder_pathname_check: folder_pathname_check,
            folder_id: null
          });
          console.log("folder_pathname", folder_pathname);
          this.showfileupload.push({
            id: this.numid,
            icon: gb_setIconFiles.fn_seticon("folder"),
            type: "folder",
            name: currentpath_withoutfile[i],
            status: {
              code: "start",
              message: "dragdrop.uploading",
              devMessage: ""
            },
            path: folder_pathname,
            percentage: 0,
            file: "",
            file_duplicate: "",
            canceltoken: "",
            chunkkey: "",
            numchunk: 0,
            allchunk: [],
            folderid: "",
            size: 0,
            value: 0,
            color: this.color.theme,
            copy_paste: false,
            parts: [],
            datafile: {},
            listxhr: [],
            checkcancelone: false,
          });
          this.numid++
        }
      } else {
        let folder_pathname = "";
        let folder_pathname_check = "";
        for (let i = 0; i < currentpath_withoutfile.length; i++) {
          if (i === 0) {
            folder_pathname += "/";
          } else {
            folder_pathname += currentpath_withoutfile[i-1] + "/";
          }
          if (i === 0) {
            folder_pathname_check = currentpath_withoutfile[i];
          } else {
            folder_pathname_check += "/" + currentpath_withoutfile[i];
          }
          let indexFolderParent = this.folderParent.findIndex(
            r => r.folder_pathname_check === folder_pathname_check
          );
          // let indexFolderParent = this.folderParent.findIndex(
          //   r => r.folder_name === currentpath_withoutfile[i]
          // );
          if (indexFolderParent === -1) {
            this.folderParent.push({
              folder_name: currentpath_withoutfile[i],
              folder_pathname_check: folder_pathname_check,
              folder_pathname: folder_pathname,
              folder_id: null
            });
            console.log("folder_pathname", folder_pathname);
            this.showfileupload.push({
              id: this.numid,
              icon: gb_setIconFiles.fn_seticon("folder"),
              type: "folder",
              name: currentpath_withoutfile[i],
              status: {
                code: "start",
                message: "dragdrop.uploading",
                devMessage: ""
              },
              path: folder_pathname,
              percentage: 0,
              file: "",
              file_duplicate: "",
              canceltoken: "",
              chunkkey: "",
              numchunk: 0,
              allchunk: [],
              folderid: "",
              size: 0,
              value: 0,
              color: this.color.theme,
              copy_paste: false,
              parts: [],
              datafile: {},
              listxhr: [],
              checkcancelone: false,
            });
            this.numid++
          }
        }
      }

      this.rawfileupload.push({
        file_name: _file.name,
        file_type: _file.name.split(".")[_file.name.split(".").length - 1],
        file_path: currentpath_withoutfile.join("/"),
        file_data: _file
      });
      this.showfileupload.push({
        id: this.numid,
        icon: gb_setIconFiles.fn_seticon(_file.name.split(".")[_file.name.split(".").length - 1]),
        type: _file.name.split(".")[_file.name.split(".").length - 1],
        name: _file.name,
        status: {
          code: "start",
          message: "dragdrop.uploading",
          devMessage: ""
        },
        path: "/" + currentpath_withoutfile.join("/") + "/",
        percentage: 0,
        file: _file,
        file_duplicate: "",
        canceltoken: "",
        chunkkey: "",
        numchunk: 0,
        allchunk: [],
        folderid: "",
        size: _file.size,
        value: 0,
        color: this.color.theme,
        copy_paste: false,
        parts: [],
        datafile: {},
        listxhr: [],
      });
      this.numid++
    },

    // เก็บไฟล์/โฟลเดอร์ที่ซ้ำ
    addFolderDuplicate(filedata) {
      filedata["percentage"] = 100;
      filedata["status"] = {
        code: "duplicate",
        message: "dragdrop.duplicatefolder",
        devMessage: ""
      };
      // สำหรับเก็บไว้เตรียมอัปโหลดซ้ำ
      let datafileduplicate = {
        id: filedata.id,
        icon: filedata.icon,
        type: filedata.type,
        name: filedata.name,
        status: {
          code: "start",
          message: "dragdrop.uploading",
          devMessage: ""
        },
        path: filedata.path,
        percentage: 0,
        rawfile: filedata.rawfile,
        file_duplicate: filedata.file_duplicate,
        file: filedata.file,
        canceltoken: "",
        chunkkey: "",
        numchunk: 0,
        allchunk: [],
        folderid: "",
        size: 0,
        value: 0,
        color: this.color.theme,
      }
      // เข้าเมื่อโฟลเดอร์นี้อยู่ในโฟลเดอร์ที่ซ้ำ
      if (
        datafileduplicate.type === "folder" &&
        this.listpathfolderduplicate.includes(filedata.path) === true
      ) {
        let pathfolder = datafileduplicate.path + datafileduplicate.name + "/";
        let mainfolder = datafileduplicate.path.split("/");
        datafileduplicate.mainfolder = mainfolder[1];
        this.listpathfolderduplicate.push(pathfolder);
        this.listfileinfolderduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อเป็นโฟลเดอร์
      else if (datafileduplicate.type === "folder") {
        let pathfolder = datafileduplicate.path + datafileduplicate.name + "/";
        this.listpathfolderduplicate.push(pathfolder);
        this.listfileduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อไฟล์นี้อยู่ในโฟลเดอร์ที่ซ้ำ
      else if (
        datafileduplicate.type !== "folder" &&
        this.listpathfolderduplicate.includes(filedata.path) === true
      ) {
        let mainfolder = datafileduplicate.path.split("/");
        datafileduplicate.mainfolder = mainfolder[1];
        this.listfileinfolderduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อเป็นไฟล์
      else {
        this.listfileduplicate.push(datafileduplicate);
      }
    },
    // ฟังก์ชัน เช็คไฟล์ซ้ำ
    async checkDuplicateData(i) {
      // หาที่ folder_id ที่เป็นที่อยู่ของไฟล์/โฟลเดอร์
      let parentsubfolder = "";
      let path = this.showfileupload[i]["path"].split("/");
      if (path.length > 1 || this.showfileupload[i]["type"] !== "folder") {
        // เช็ค path เพื่อตรวจสอบ parent folder
        for (let j = 0; j < this.showfileupload.length; j++) {
          if (this.showfileupload[j]["type"] === "folder") {
            if (
              this.showfileupload[j]["name"] === path[path.length - 1]
            ) {
              parentsubfolder = this.showfileupload[j]["folderid"];
            }
          }
        }
      }
      let payload = {
        folder_id: parentsubfolder === "" ? this.parentfolder : parentsubfolder,
        data_type: this.showfileupload[i]["type"] === "folder" ? "folder" : "file",
        data_name: this.showfileupload[i]["name"]
      }
      let auth = await gbfGenerate.generateToken();
      const CancelToken = this.axios.CancelToken;
      this.source = CancelToken.source();
      this.showfileupload[i]["canceltoken"] = this.source;
      try {
        let response = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/check_duplicate_data",
          cancelToken: this.showfileupload[i].canceltoken.token,
          data: payload,
          headers: { Authorization: auth.code },
        })
        if (response.data.status === "OK") {
          if (response.data.message === "duplicate foldername" || response.data.message === "duplicate filename") {
            if (this.showfileupload[i]["type"] === "folder") {
              this.showfileupload[i]["folderid"] = response.data.data.folder_id;
            }
            this.percentfileuploading = 100;
            this.showfileupload[i]["percentage"] = 100;
            this.showfileupload[i]["status"] = {
              code: "duplicate",
              message: "dragdrop.duplicatefolder",
              devMessage: ""
            };
            return new Promise(async (resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: "duplicate",
                  msg: response.data.message,
                });
              }, 1500);
            });
          } else {
            return new Promise(async (resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: "upload success",
                  msg: response.data.message,
                });
              }, 1500);
            });
          }
        } else {
          return new Promise(async (resolve, reject) => {
            setTimeout(() => {
              resolve({
                status: "upload denied",
                msg: response.data.errorMessage,
              });
            }, 1500);
          });
        }
      } catch (err) {
        console.log("err", err);
        if (this.count_cancel === 1) {
          return new Promise(async (resolve, reject) => {
            this.showfileupload[i]["color"] = "red";
            this.showfileupload[i]["icon_status"] = "priority_high";
            setTimeout(() => {
              resolve({
                status: "upload denied",
                msg: "Operation canceled by the user",
              });
            }, 1500);
          });
        } else {
          return new Promise(async (resolve, reject) => {
            this.showfileupload[i]["color"] = "red";
            this.showfileupload[i]["icon_status"] = "priority_high";
            setTimeout(() => {
              resolve({
                status: "upload denied",
                msg: err,
              });
            }, 1500);
          });
        }
      }
    },
    // ทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ
    async setStatusFileDuplicate(selectedduplicate, fileduplicate) {
      if (fileduplicate === "") {
        this.cancel_upload();
        this.listfileduplicate = [];
        this.openalertduplicatefile = false;
      } else {
        try {
          let listnewshowupload = selectedduplicate;
          for (let i = 0; i < selectedduplicate.length; i++) {
            let indexduplicate = this.listfileduplicate.indexOf(
              selectedduplicate[i]
            );
            this.listfileduplicate.splice(indexduplicate, 1);
            if (this.listfileduplicate.length === 0) {
              this.openalertduplicatefile = false;
            }
            this.showfileupload[selectedduplicate[i].id]["file_duplicate"] = fileduplicate;
            if (fileduplicate === "") {
              console.log("selectedduplicate[i]", selectedduplicate[i]);
              this.showfileupload[selectedduplicate[i].id]["color"] = "red";
              this.showfileupload[selectedduplicate[i].id]["status"] = {
                code: "cancel",
                message: "dragdrop.cancelupload",
                devMessage: ""
              };
              this.showfileupload[selectedduplicate[i].id]["color"] = "red";
            } else {
              if (this.count_cancel === 1) {
                break;
              } else {
                console.log("เข้าตรงนี้ใช่มั้ย")
                // this.count_upload_duplicate++
                let count_uploading = 0;
                this.btnClosedetail = false;
                if (this.showfileupload[selectedduplicate[i].id]["type"] === "folder") {
                  await this.fn_reupload_duplicate(this.showfileupload[selectedduplicate[i].id], count_uploading);
                } else if (count_uploading <= 5) {
                  if (this.showfileupload[selectedduplicate[i].id].size > 5368709120) {
                    await this.fn_reupload_duplicate(this.showfileupload[selectedduplicate[i].id], count_uploading);
                  } else {
                    this.fn_reupload_duplicate(this.showfileupload[selectedduplicate[i].id], count_uploading);
                  }
                  count_uploading++;
                } else {
                  await this.fn_reupload_duplicate(this.showfileupload[selectedduplicate[i].id], count_uploading);
                  count_uploading = 0;
                }
              }
            }
            if (selectedduplicate[i]["type"] === "folder") {
              let chidren = this.listfileinfolderduplicate.filter(
                (x) => x["mainfolder"] == selectedduplicate[i]["name"]
              );
              if (chidren.length > 0) {
                for (let j = 0; j < chidren.length; j++) {
                  chidren[j]["file_duplicate"] = fileduplicate;
                  let indexduplicateinfolder = this.listfileinfolderduplicate.indexOf(
                    chidren[j]
                  );
                  this.listfileinfolderduplicate.splice(indexduplicateinfolder, 1);
                  if (this.listfileduplicate.length === 0) {
                    this.openalertduplicatefile = false;
                  }
                  if (fileduplicate === "") {
                    this.showfileupload[chidren[j].id]["color"] = "red";
                    this.showfileupload[chidren[j].id]["status"] = {
                      code: "cancel",
                      message: "dragdrop.cancelupload",
                      devMessage: ""
                    };
                  } else {
                    if (this.count_cancel === 1) {
                      break;
                    } else {
                      this.showfileupload[chidren[j].id]["file_duplicate"] = fileduplicate;
                      this.showfileupload[chidren[j].id]["color"] = this.color.theme;
                      this.showfileupload[chidren[j].id]["status"] = {
                        code: "start",
                        message: "dragdrop.uploading",
                        devMessage: ""
                      };
                      this.btnClosedetail = false;
                      let count_upload_chidren = 0;
                      if (this.showfileupload[chidren[j].id]["type"] === "folder") {
                        await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                      } else if (count_upload_chidren <= 5) {
                        if (this.showfileupload[chidren[j].id].size > 5368709120) {
                          await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                        } else {
                          this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                        }
                        count_upload_chidren++;
                      } else {
                        await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                        count_upload_chidren = 0;
                      }
                    }
                  }
                }
              }
              listnewshowupload = listnewshowupload.concat(chidren);
            }
          }
          if (fileduplicate !== "") {
          }

          if (this.listfileduplicate.length === 0) {
            if (fileduplicate === "" && this.length_processing === this.showfileupload.length) {
              this.loopsuccess = true;
            }
            this.openalertduplicatefile = false;
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    async fn_reupload_duplicate(item, count_upload) {
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      if (item.type === "folder") {
        await this.fn_callapicreatefolder(item, objIndex);
      } else {
        this.showfileupload[objIndex]["numchunk"] = 0;
        this.showfileupload[objIndex]["listxhr"] = [];
        this.showfileupload[objIndex]["part"] = [];
        this.showfileupload[objIndex]["allchunk"] = [];
        this.showfileupload[objIndex]["status"] = {
          code: "Uploading",
          message: "dragdrop.uploading",
          devMessage: ""
        };
        this.fnCheckingFileData(
          this.showfileupload[objIndex],
          objIndex,
          this.showfileupload[objIndex]["numchunk"]
        );
      }
    },
    // STEP6: START LOOP UPLOAD & CREATE FOLDER
    async fn_uploadfileandfolder() {
      this.minidisplay = true;
      // this.checkcurrentupload = true;
      let i = 0;
      let responsecheckduplicate = {};
      let count_upload = 0;
      this.count_processing = 0;
      // this.loopcheckupload = setInterval(this.fn_checkupload, 1000);
      // this.folderupload = this.showfileupload[0].name
      this.percentupload+= 100/this.showfileupload.length
      this.currentupload = this.showfileupload[i].name;
      this.datauploading = this.showfileupload[i];
      if (i === 0) {
        responsecheckduplicate = this.checkfirstloopchoice === true ? "" : await this.checkDuplicateData(i);
      }
      if (responsecheckduplicate.status === "duplicate") {
        for (let j = 0; j < this.showfileupload.length; j++) {
          // this.folderupload = this.showfileupload[0].name
          this.percentupload+= 100/this.showfileupload.length
          this.currentupload = this.showfileupload[j].name;
          this.datauploading = this.showfileupload[j];
          await this.addFolderDuplicate(this.showfileupload[j]);
          this.count_processing = this.count_processing + 1;
        }
        this.openalertduplicatefile = true;
      } else {
        while(i < this.showfileupload.length){
          if (this.count_cancel === 0) {
            this.count_processing = this.count_processing + 1;
            this.percentupload+= 100/this.showfileupload.length
            try {
              if (this.showfileupload[i]["type"] !== "folder") {
                await this.$store.dispatch("check_storage", this.dataAccountId);
                this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(
                  this.dataRemainStorage
                );
              }
              if (
                this.remain_storage < this.showfileupload[i]["size"] &&
                this.showfileupload[i]["type"] !== "folder"
              ) {
                // this.count_uploadfail++;
                this.status_uploadfail = true;
                this.showfileupload[i]["value"] = 100;
                this.showfileupload[i]["status"] = {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                };
                this.showfileupload[i]["color"] = "red";
                this.percentfileuploading = this.showfileupload[i]["value"];
              } else if (
                this.remain_storage < 0 ||
                (this.remain_storage <
                  this.stacksizefile + this.showfileupload[i]["size"] &&
                  this.showfileupload[i]["type"] !== "folder")
              ) {
                // this.count_uploadfail++;
                this.status_uploadfail = true;
                this.showfileupload[i]["value"] = 100;
                this.showfileupload[i]["status"] = {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                };
                this.showfileupload[i]["color"] = "red";
                this.percentfileuploading = this.showfileupload[i]["value"];
              } else {
                this.showfileupload[i]["canceltoken"] = CancelToken.source();
                this.currentupload = this.showfileupload[i].name;
                this.datauploading = this.showfileupload[i];
                if (this.showfileupload[i].type === "folder") {
                  await this.fn_callapicreatefolder(this.showfileupload[i], i);
                  count_upload = 0;
                  i++
                } else {
                  this.stacksizefile =
                    this.stacksizefile + this.showfileupload[i]["size"];
                  if (count_upload < 4) {
                    this.fn_callapiuploadfiles(this.showfileupload[i], i);
                    count_upload = count_upload + 1;
                    i++
                  } else {
                    await this.fn_callapiuploadfiles(this.showfileupload[i], i);
                    count_upload = 0
                    i++
                  }
                }
              }
            } catch (err) {
              console.log(err);
              if(!err.message){
                if (err.type === "folder") {
                  this.fn_terminatefile(i);
                  break;
                } else {
                  continue;
                }
              }
            }
          } else {
            break;
          }
        }
      }
    },
    // STEP6.1: CALL API UPLOAD FOLDER
    async fn_callapicreatefolder(_filefolder, _index) {
      var custom_folder = _filefolder;
      try {
        let payload;
        if (custom_folder.path === "/") {
          payload = {
            folder_name: custom_folder.name,
            user_id: this.dataUsername,
            id: this.parentfolder,
            account_id: this.dataAccountId,
            // department_id: this.dataDepartmentAccessId
            department_id: this.departmentid,
            folder_duplicate: custom_folder.file_duplicate,
          };
        } else {
          // เช็ค path เพื่อตรวจสอบ parent folder
          let folderid = ""
          for (let i = 0; i < this.showfileupload.length; i++) {
            if (this.showfileupload[i]["type"] === "folder") {
              if (
                custom_folder.path === this.showfileupload[i].path + this.showfileupload[i].name + "/"
              ) {
                folderid = this.showfileupload[i]["folderid"];
              }
            }
          }
          payload = {
            folder_name: custom_folder.name,
            user_id: this.dataUsername,
            id: folderid,
            account_id: this.dataAccountId,
            // department_id: this.dataDepartmentAccessId
            department_id: this.departmentid,
            folder_duplicate: custom_folder.file_duplicate,
          };
        }
        let url;
        if (this.$route.name === "directorysharefile") {
          url =
            process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
            "/api/insert_folder_share";
        } else {
          url =
            process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder";
        }
        let auth = await gbfGenerate.generateToken();
        let call_api = await this.axios.post(url, payload, {
          headers: { Authorization: auth.code }
        });

        if (call_api.data.status === "OK") {
          this.percentfileuploading = 100;
          custom_folder.value = 100;
          custom_folder.status = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          custom_folder.color = "success"
          custom_folder.folderid = call_api.data.data.folder_id
          this.checkupfolder = false;
            return new Promise(resolve => {
              setTimeout(() => {
                resolve({
                  status: "OK",
                  msg: "Upload File Success",
                  type: "folder"
                });
              }, 1500);
            });
        } else {
          this.percentfileuploading = 100;
          custom_folder.value = 100;
          custom_folder.status = 
            call_api.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: call_api.data.errorMessage,
                }
              ;
          custom_folder.color = "red"
          this.closeSnackbar = true;
          this.btnClosedetail = true;
          this.checkupfolder = false;
          throw { status: "ER", msg: call_api.data.errorMessage };
        }
      } catch (err) {
        console.log(err);
        this.percentfileuploading = 100;
        this.checkupfolder = false;
        custom_folder.value = 100;
        if (this.count_cancel === 1 || custom_folder.checkcancelone === true) {
          custom_folder.status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          custom_folder.color = "red"
        } else {
          custom_folder.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          custom_folder.color = "red"
        }
        this.closeSnackbar = true;
        this.btnClosedetail = true;
        // }
      }
    },

    // STEP6.2: CALL API UPLOAD FOLDER
    async fn_callapiuploadfiles(_filedata, _index) {
      let custom_file = _filedata;
      try {
        // CHECK MAXIMUM FILES SIZES
        // 5368709120 = 5GB
        // if (_filedata.size >= 5368709120) {
        //   _filedata.value = 100;
        //   _filedata.status = {
        //     code: "fail",
        //     message: "dragdrop.morefivegb",
        //     devMessage: err,
        //   };
        //   _filedata.color = "red"
        // } else {
          // CHECK PARENT FOLDER
          // if (_filedata.size > 104857600) {
          // if (_filedata.size > 524288000) {
          //   await this.fn_upload_chunk(_index);
          //   setTimeout(() => {
          //     return new Promise(resolve => {
          //       setTimeout(() => {
          //         resolve({
          //           status: "OK",
          //           msg: "Upload File Success",
          //           type: "file"
          //         });
          //       }, 1500);
          //     });
          //   }, 1000);
          // } else {
            let folderid = "";
            for (let i = 0; i < this.showfileupload.length; i++) {
              if (this.showfileupload[i]["type"] === "folder") {
                if (
                  _filedata.path === this.showfileupload[i].path + this.showfileupload[i].name + "/"
                ) {
                  folderid = this.showfileupload[i]["folderid"];
                }
              }
            }
            const formData = new FormData();
            formData.append("file_name", _filedata.name);
            formData.append("file", _filedata.file);
            formData.append("size_file", _filedata.size);
            formData.append("folder_id", folderid);
            formData.append("user_id", this.dataUsername);
            formData.append("department_id", this.departmentid);
            formData.append("account_id", this.dataAccountId);
            formData.append("file_duplicate", _filedata.file_duplicate);
            formData.append("remark", "");
            formData.append("old_version_file_id", "");
            formData.append(`status_encrypt`, "N");
            if (this.$route.name === "directorysharefile") {
              formData.append("folder_form_share", "Y");
            }
            // await this.fn_uploadfile(formData, _filedata, _index, custom_file);
            await this.fnCheckingFileData(_filedata, _index)
          // }
        // }
      } catch (err) {
        console.log(err);
        this.percentfileuploading = 100;
        custom_file.value = 100;
        custom_file.color = "red"
        if (this.count_cancel === 1 || custom_file.checkcancelone === true) {
          custom_file.status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          custom_file.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
        this.closeSnackbar = true;
        this.btnClosedetail = true;
        // }
      }
    },
    async checkUploading(i) {
      let responsecheckduplicate = {};
      console.log("this.showfileupload[i]", this.showfileupload[i]);
      if (i === 0) {
        responsecheckduplicate = this.checkfirstloopchoice === true ? "" : await this.checkDuplicateData(i);
      }
      if (responsecheckduplicate.status === "duplicate") {
        for (let j = 0; j < this.showfileupload.length; j++) {
          // this.folderupload = this.showfileupload[0].name
          this.percentupload+= 100/this.showfileupload.length
          this.currentupload = this.showfileupload[j].name;
          this.datauploading = this.showfileupload[j];
          await this.addFolderDuplicate(this.showfileupload[j]);
          this.count_processing = this.count_processing + 1;
        }
        this.conutindexupload = this.showfileupload.length
        this.openalertduplicatefile = true;
        this.checkupfolder = false;
      } else {
        this.showfileupload[i]
        ? (this.showfileupload[i].status = {
            code: "Uploading",
            message: "dragdrop.uploading",
            devMessage: ""
          })
        : "";
        // ต้องไม่มี error และต้องไม่ cencel
        if (
          this.showfileupload.length !== i &&
          // this.showfileupload[i].errorduplicate === "not" &&
          this.showfileupload[i].status.code !== "cancel"
        ) {
          if (this.showfileupload[i]["type"] == "folder") {
            await this.fn_callapicreatefolder(this.showfileupload[i], i);
          } else {
            // ยิงเช็ค storage ยกเว้นหน้า directorysharefile
            // if (this.$route.name !== "directorysharefile") {
            //   await this.fnCheckStorage();
            // }
            // พื้นที่คงเหลือมากกว่าขนาดไฟล์หรือเป็นหน้า directorysharefile
            // console.log(
            //   "this.userdata.remain_storage",
            //   this.userdata.remain_storage
            // );
            // console.log(
            //   "this.showfileupload[i].size",
            //   this.showfileupload[i].size
            // );
            // if (
            //   this.userdata.remain_storage > this.showfileupload[i].size ||
            //   this.$route.name === "directorysharefile"
            // ) {
            let folderid = "";
            for (let j = 0; j < this.showfileupload.length; j++) {
              if (this.showfileupload[j]["type"] === "folder") {
                if (
                  this.showfileupload[i].path === this.showfileupload[j].path + this.showfileupload[j].name + "/"
                ) {
                  folderid = this.showfileupload[j]["folderid"];
                }
              }
            }
            const formData = new FormData();
            formData.append("file_name", this.showfileupload[i].name);
            formData.append("file", this.showfileupload[i].file);
            formData.append("size_file", this.showfileupload[i].size);
            formData.append("folder_id", folderid);
            formData.append("user_id", this.dataUsername);
            formData.append("department_id", this.departmentid);
            formData.append("account_id", this.dataAccountId);
            formData.append("file_duplicate", this.showfileupload[i].file_duplicate);
            formData.append("remark", "");
            formData.append("old_version_file_id", "");
            formData.append(`status_encrypt`, "N");
            formData.append("hashtag", "");
            if (this.$route.name === "directorysharefile") {
              formData.append("folder_form_share", "Y");
            }
            this.showfileupload[i].formData = formData;
            this.fnCheckingFileData(
              this.showfileupload[i],
              i,
              this.showfileupload[i]["numchunk"]
            );
            // } 
            // else {
            //   this.statusstorage = true;
            //   this.showfileupload[i].status = {
            //     code: "fail",
            //     message: "dragdrop.cannotdetailupload",
            //     devMessage: "",
            //   };
            //   this.showfileupload[i].color = "error";
            //   this.createprogress = false;
            // }
          }
        } 
        // else {
        //   this.showfileupload[i]
        //     ? (this.showfileupload[i].status = {
        //       code: "duplicate",
        //       message: "dragdrop.cannotdetailupload",
        //       devMessage: err,
        //     })
        //     : "";
        // }
      }
    },
    callfolderid(_filedata) {
      let folderid = "";
      for (let i = 0; i < this.showfileupload.length; i++) {
        if (this.showfileupload[i]["type"] === "folder") {
          if (
            _filedata.path === this.showfileupload[i].path + this.showfileupload[i].name + "/"
          ) {
            folderid = this.showfileupload[i]["folderid"];
            return folderid;
          }
        }
      }
    },
    async fnCheckingFileData(_filedata, _index, numchunk) {
      const CancelToken = this.axios.CancelToken;
      this.source = CancelToken.source();
      _filedata.canceltoken = this.source;
      try {
        let auth = await gbfGenerate.generateToken();
        let folderid = this.callfolderid(_filedata)
        _filedata.canceltoken = CancelToken.source();
        let payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folderid,
          file_duplicate: _filedata.file_duplicate,
          filename:  _filedata.name,
          remark: "",
          size_file: _filedata.size,
          department_id: this.departmentid,
          hashtag: []
        }
        if (this.$route.name === "directorysharefile") {
          payload.folder_from_share = "Y";
        }
        let call_api = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/checking_file_data/upload",
          cancelToken: _filedata.canceltoken.token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (call_api.data.status === "OK") {
          if (Object.keys(call_api.data.data.uploadChunk).length > 0) {
            this.datachunkwaiting = _filedata;
            _filedata["datafile"] = call_api.data.data;
            this.fnuploadChunks3(call_api.data.data, _index, 0, "");
          } else {
            this.fnUploads3(call_api.data.data, _filedata, _index);
          }
        } else {
          this.percentfileuploading = 100;
          _filedata.value = 100;
          _filedata.color = "red"
          _filedata.status = 
            call_api.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: call_api.data.errorMessage,
                }
              ;
          this.closeSnackbar = true;
          this.btnClosedetail = true;
        }
      } catch(err) {
        console.log("err", err);
        _filedata.value = 100;
        _filedata.color = "red"
        if (this.count_cancel === 1 || _filedata.checkcancelone === true) {
          _filedata.status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          _filedata.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
      }
    },
    async fnuploadChunks3(datafile, i, numchunk, checkreload) {
      try {
        if (numchunk === 0 && checkreload === "") {
          await this.create_chunk_new(this.showfileupload[i]);
          await this.generate_uuid(i);
          this.checkopenchunk = true;
          this.showfileupload[i]["parts"] = [];
          this.chunkwaiting = this.chunkwaiting.concat(this.showfileupload[i]["allchunk"])
        }
        if (this.showfileupload[i]["allchunk"].length > 0) {
          if (this.showfileupload[i]["status"].code === "Uploading") {
            try {
              let url = await this.fnGetPresignChunk(
                datafile,
                i,
                this.showfileupload[i]["allchunk"][numchunk]["PartNumber"]
              );
              if (url !== "fail") {
                this.showfileupload[i]["allchunk"][numchunk]["status"] ===
                  "Uploading";
                let onechunkwaiting = this.chunkwaiting.filter(item => item.id === this.showfileupload[i]["id"]);
                let indexwatingchunk = this.chunkwaiting.indexOf(onechunkwaiting[numchunk])
                this.chunkwaiting[indexwatingchunk].status = "Uploading"
                console.log("this.chunkwaiting[i]", onechunkwaiting[numchunk]);
                let result
                if (url !== undefined) {
                  result = await this.makeRequest(
                    "PUT",
                    url,
                    i,
                    numchunk
                  );
                }
                if (result.data.status === 200) {
                  let headers = await this.parseResponseHeaders(result.headers);
                  headers.etag = headers.etag.replace('"', "");
                  headers.etag = headers.etag.replace('"\ ', "");
                  headers.etag = headers.etag.replace('"', "");
                  let datachunk = {
                    PartNumber: this.showfileupload[i]["allchunk"][numchunk][
                      "PartNumber"
                    ],
                    ETag: headers.etag,
                  };
                  this.showfileupload[i]["parts"].push(datachunk);
                  console.log(
                    "this.showfileupload[i]",
                    this.showfileupload[i]["allchunk"][numchunk]["parts"]
                  );
                  this.showfileupload[i]["allchunk"][numchunk]["status"] =
                    "Success";
                  console.log("this.chunkwaiting", this.chunkwaiting);
                  console.log("indexwatingchunk", indexwatingchunk);
                  this.chunkwaiting[indexwatingchunk].status = "Success";
                  this.percentfileuploading = this.showfileupload[i]["value"];
                  let countchunksuccess = this.showfileupload[i][
                    "allchunk"
                  ].filter((item) => item.status === "Success").length;
                  if (
                    countchunksuccess === this.showfileupload[i]["allchunk"].length
                  ) {
                    if (this.showfileupload[i]["status"].code === "Uploading") {
                      await this.fnInsertFileData(datafile, this.showfileupload[i], i, "chunk");
                      if (this.chunkwaiting.filter((item) => item.status === "Uploading").length == 0) {
                        this.chunkwaiting = [];
                        this.checkopenchunk = false;
                      }
                    }
                  }
                } else {
                  setTimeout(() => {
                    this.fnuploadChunks3(datafile, i, numchunk, "reload")
                  }, 5000);
                  // this.showfileupload[i].status = {
                  //   code: "fail",
                  //   message: "dragdrop.cannotdetailupload",
                  //   devMessage: "",
                  // };
                  // this.chunkwaiting = [];
                  // this.checkopenchunk = false;
                }
              }
            } catch (error) {
              console.log(error);
              setTimeout(() => {
                this.fnuploadChunks3(datafile, i, numchunk, "reload")
              }, 5000);
            }
          }
        }
      } catch (error) {
        console.log("objerrorect", error);
        this.showfileupload[i].value = 100;
        this.showfileupload[i].color = "red"
        if (this.count_cancel === 1 || this.showfileupload[i].checkcancelone === true) {
          this.showfileupload[i].status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          this.showfileupload[i].status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: error,
          };
        }
        this.chunkwaiting = [];
        this.checkopenchunk = false;
      }
    },
    async makeRequest(method, url, i, numchunk) {
      // รับค่า blob data จาก showfileupload
      let data = this.showfileupload[i]["allchunk"][numchunk]["blob"];
      
      // สร้าง XMLHttpRequest object
      const xhr = new XMLHttpRequest();
      const self = this;
  
      // เพิ่ม XMLHttpRequest object นี้ไปใน listxhr ของ showfileupload
      this.showfileupload[i]["listxhr"].push(xhr);

      // คำนวณเปอร์เซ็นต์ที่แต่ละ request จะได้รับ
      const len_file = Math.ceil(this.showfileupload[i]["allchunk"].length / this.part_per_send);
      const chunkProgress = 100 / len_file;

      // ตัวแปรสำหรับเก็บ progress ของ chunk ปัจจุบัน
      let currentProgress = 0;
      
      // Return a new promise
      return new Promise(function(resolve, reject) {
        xhr.open(method, url, true);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            // คำนวณเปอร์เซ็นต์ของ progress
            let percentComplete = (event.loaded / event.total) * chunkProgress;
            
            // อัพเดตค่า self.temparraydataupload[i]["value"] โดยใช้ค่าที่แตกต่างจาก previous progress
            self.showfileupload[i]["value"] += percentComplete - currentProgress;

            // อัพเดต currentProgress สำหรับ chunk ปัจจุบัน
            currentProgress = percentComplete;
          }
        };
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            let responseHeaders = xhr.getAllResponseHeaders();
            resolve({ headers: responseHeaders, data: xhr });
          } else {
            reject(xhr.statusText);
          }
        };

        xhr.onerror = function() {
          if (xhr.status === 0) {
            // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
            reject("ERR_INTERNET_DISCONNECTED");
          } else {
            reject(xhr.statusText);
          }
        };

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 0) {
            // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
            reject("ERR_INTERNET_DISCONNECTED");
          }
        };
        xhr.send(data);
      });
    },
    parseResponseHeaders(headerString) {
      const headers = {};
      const headerPairs = headerString.split("\r\n");
      headerPairs.forEach(function(headerPair) {
        const index = headerPair.indexOf(":");
        if (index > 0) {
          const key = headerPair.substring(0, index).trim();
          const value = headerPair.substring(index + 1).trim();
          headers[key] = value;
        }
      });
      return headers;
    },
    async fnGetPresignChunk(datafile, i, partnumber) {
      try {
        let payload = {
          bucket_name: datafile.uploadChunk.bucket_name,
          object_name: datafile.uploadChunk.object_name,
          upload_id: datafile.uploadChunk.upload_id,
          part_number: partnumber,
        };
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/generate_presigned_url/upload/chunks",
          cancelToken: this.showfileupload[i]["canceltoken"]["token"],
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {},
        });
        if (result.data.status === "OK") {
          return result.data.data.url;
        } else if (result.data.errorMessage === "Your space not enough") {
          this.showfileupload[i].status = {
            code: "fail",
            message: "dragdrop.yourspacenotenough",
            devMessage: "",
          };
          this.showfileupload[i].value = 100;
          this.showfileupload[i].color = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return "Your space not enough";
        } else {
          // this.showfileupload[i].status = {
          //   code: "fail",
          //   message: "dragdrop.yourspacenotenough",
          //   devMessage: "",
          // };
          // this.showfileupload[i].value = 100;
          // this.showfileupload[i].color = "error";
          // this.chunkwaiting = [];
          // this.checkopenchunk = false;
          return undefined;
        }
      } catch (error) {
        if (this.count_cancel === 1 || this.showfileupload[i].checkcancelone === true) {
          this.showfileupload[i].value = 100;
          this.showfileupload[i].color = "red"
          this.showfileupload[i].status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          this.checkopenchunk = false;
          return "cancel";
        } else {
          // this.showfileupload[i].status = {
          //   code: "fail",
          //   message: "dragdrop.cannotdetailupload",
          //   devMessage: err,
          // };
          return undefined;
        }
      }
    },
    async fnUploads3(datafile, _filedata, _index) {
      try {
        let auth = await gbfGenerate.generateToken();
        // const CancelToken = this.axios.CancelToken;
        // this.source = CancelToken.source();
        // _filedata.canceltoken = this.source;
        const formData = new FormData();
        formData.append("policy", datafile.presigned_url.fields.policy);
        formData.append("signature", datafile.presigned_url.fields.signature);
        formData.append("AWSAccessKeyId", datafile.presigned_url.fields.AWSAccessKeyId);
        formData.append("key", datafile.presigned_url.fields.key);
        formData.append("file", _filedata.file);
        let call_api = await this.axios({
          method: "POST",
          url: datafile.presigned_url.url,
          cancelToken: _filedata.canceltoken.token,
          data: formData,
          headers: { Authorization: auth.code, "Content-Type": "multipart/form-data" },
          onUploadProgress: progressEvent => {
            if ((progressEvent.loaded / progressEvent.total) * 100 >= 90) {
              _filedata.value = 90;
              this.percentfileuploading = _filedata.value;
            } else {
              _filedata.value = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              this.percentfileuploading = _filedata.value;
            }
          }
        });
        if (call_api.status === 204) {
          this.fnInsertFileData(datafile, _filedata, _index, "nochunk");
        } else {
          _filedata.value = 100;
          _filedata.color = "red"
          _filedata.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: call_api.data.errorMessage,
          };
        }
      } catch(err) {
        console.log("err", err);
        _filedata.value = 100;
        _filedata.color = "red"
        if (this.count_cancel === 1 || _filedata.checkcancelone === true) {
          _filedata.status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          _filedata.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
      }
    },
    async fnInsertFileData(datafile, _filedata, _index, status) {
      try {
        let auth = await gbfGenerate.generateToken();
        let payload = {
          account_id: datafile.account_id,
          archive_file: datafile.archive_file,
          business_id: datafile.business_id,
          data_type: datafile.data_type,
          department_id: datafile.department_id,
          file_id: datafile.file_id,
          file_id_copied: datafile.file_id_copied,
          filename: datafile.filename,
          folder_id: datafile.folder_id,
          hashtag: datafile.hashtag,
          remark: "",
          size_file: datafile.size_file,
          user_id: datafile.user_id,
          file_duplicate: _filedata.file_duplicate,
          folder_from_share: datafile.folder_from_share,
        }
        if (status === "chunk") {
          payload.complete_file_chunk = {
            bucket_name: datafile.uploadChunk.bucket_name,
            object_name: datafile.uploadChunk.object_name,
            upload_id: datafile.uploadChunk.upload_id,
            parts: this.showfileupload[_index]["parts"].sort(
              (a, b) => a.PartNumber - b.PartNumber
            ),
          };
        }
        let call_api = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/insert_file_data",
          cancelToken: _filedata.canceltoken.token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (call_api.data.status === "OK") {
          this.percentfileuploading = 100;
          this.showfileupload[_index].value = 100;
          this.showfileupload[_index].color = "success"
          this.showfileupload[_index].status = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.stacksizefile = this.stacksizefile - this.showfileupload[_index]["size"];
          // if (this.length_total === this.length_success) {
          //   this.btnClosedetail = true;
          //   setTimeout(() => this.fn_endprocess_success(), 2500);
          // }
        } else {
          this.showfileupload[_index].value = 100;
          this.showfileupload[_index].color = "red"
          this.showfileupload[_index].status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
      } catch(err) {
        console.log("err", err);
        this.showfileupload[_index].value = 100;
        this.showfileupload[_index].color = "red"
        if (this.count_cancel === 1 ||this.showfileupload[_index].checkcancelone === true) {
          this.showfileupload[_index].status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          this.showfileupload[_index].status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
      }
    },
    async fn_uploadfile(formData, _filedata, _index, custom_file) {
      try {
        let auth = await gbfGenerate.generateToken();
        let call_api = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_v3",
          cancelToken: custom_file.canceltoken.token,
          data: formData,
          headers: { Authorization: auth.code },
          onUploadProgress: progressEvent => {
            if ((progressEvent.loaded / progressEvent.total) * 100 >= 90) {
              custom_file.value = 90;
              this.percentfileuploading = custom_file.value;
            } else {
              custom_file.value = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              this.percentfileuploading = custom_file.value;
            }
          }

        });
        if (call_api.data.status === "OK") {
          this.percentfileuploading = 100;
          custom_file.value = 100;
          custom_file.color = "success"
          custom_file.status = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.stacksizefile = this.stacksizefile + this.showfileupload[_index]["size"];
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: call_api.data.message,
                type: "file"
              });
            }, 1500);
          });
        } else {
          this.percentfileuploading = 100;
          custom_file.value = 100;
          custom_file.color = "red"
          custom_file.status = 
            call_api.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: call_api.data.errorMessage,
                }
              ;
          this.closeSnackbar = true;
          this.btnClosedetail = true;
        }
      } catch(err) {
        console.log("err", err);
        custom_file.value = 100;
        custom_file.color = "red"
        if (this.count_cancel === 1 || custom_file.checkcancelone === true) {
          custom_file.status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          custom_file.status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
      }
    },
    async create_chunk_new(item) {
      var size = 1073741824;
      // var size = 104857600;
      var chunks = 0;
      chunks = Math.ceil(item.size / size);
      for (let i = 0; i < chunks; i++) {
        let payload_blob = {
          status: "start",
          blob: item.file.slice(
            i * size,
            Math.min(i * size + size, item.size)
            // "text/plain"
          ),
          PartNumber: i + 1,
          parts: {},
          id: item.id
        };
        item.allchunk.push(payload_blob);
      }
    },
    // STEP6.2.1: UPLOAD FILE CHUNKFILE
    async fn_upload_chunk(_index) {
      try {
        let payload;
        let payload_merge;
        await this.create_chunk(this.showfileupload[_index]);
        await this.generate_uuid(_index);

        for (let i = 0; i < this.showfileupload[_index]["allchunk"].length; i++) {
          this.showfileupload[_index]["numchunk"] = i;
          let res_ = await this.fn_check_payload_file(_index);
          payload = res_.msg;
          let res_chunk = await this.fn_uploadfile_chunk(payload, _index);
          if (res_chunk.status === "ER") {
            break;
          }
          if (i === this.showfileupload[_index]["allchunk"].length - 1) {
            let res_p = await this.fn_check_payload_file_merge(_index);
            payload_merge = res_p.msg;
            let res_n;
            res_n = await this.fn_uploadfile_merge(payload_merge, _index);
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve({ status: res_n.status, msg: res_n.msg });
              }, 1500);
            });
          }
        }
      } catch (error) {
        this.percentfileuploading = 100;
        this.showfileupload[_index]["value"] = 100;
        this.showfileupload[_index]["color"] = "red"
        if (this.count_cancel === 1 || this.showfileupload[_index].checkcancelone === true) {
          this.showfileupload[_index]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          this.showfileupload[_index]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
        this.closeSnackbar = true;
        this.btnClosedetail = true;
      }
    },

    // STEP6.3: TERMINATE FILES & FOLDER IF CREATE FOLDER FAIL
    fn_terminatefile(_index) {
      for (let i = _index; i < this.showfileupload.length; i++) {
        this.percentfileuploading = 100;
        this.showfileupload[i].value = 100;
        this.showfileupload[i].status = "Fail";
      }
    },

    async fn_uploadfile_chunk(payload, _index) {
      try{
        let len_file = Math.ceil(this.showfileupload[_index]["allchunk"].length / this.part_per_send);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_chunk",
          cancelToken: this.showfileupload[_index]["canceltoken"]["token"],
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: e => {}
        });
        if (result.data.status === "OK") {
          this.showfileupload[_index]["value"] += (100 / (len_file + 1));
          this.percentfileuploading =  this.showfileupload[_index]["value"];
          if (result.data.message === "duplicate file") {
              return new Promise(resolve => {
                setTimeout(() => {
                  resolve({
                    status: "OK",
                    msg: "duplicate file",
                    type: "file"
                  });
                }, 1500);
              });
            } else {
              return new Promise(resolve => {
                setTimeout(() => {
                  resolve({
                    status: "OK",
                    msg: "Upload File Success",
                    type: "file"
                  });
                }, 1500);
              });
            }
        } else {
          // throw { status: "ER", msg: result.data.errorMessage };
          this.percentfileuploading = 100;
          this.showfileupload[_index]["value"] = 100;
          this.showfileupload[_index]["color"] = "red"
          this.showfileupload[_index]["status"] = 
            call_api.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: call_api.data.errorMessage,
                }
              ;
          this.closeSnackbar = true;
          this.btnClosedetail = true;
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({
                status: "ER",
                msg: result.data.errorMessage,
                type: "file"
              });
            }, 1500);
          });
        }
      } catch(err) {
        console.log(err);
        this.percentfileuploading = 100;
        this.showfileupload[_index]["value"] = 100;
        this.showfileupload[_index]["color"] = "red"
        if (this.count_cancel === 1 || this.showfileupload[_index].checkcancelone === true) {
          this.showfileupload[_index]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          this.showfileupload[_index]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
        }
        this.closeSnackbar = true;
        this.btnClosedetail = true;
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              status: "ER",
              msg: err,
              type: "file"
            });
          }, 1500);
        });
      }
    },

    async fn_uploadfile_merge(payload_merge, _index) {
      try {
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/merge_files_chunk",
          //cancelToken: this.showfileupload[objIndex]["canceltoken"],
          data: payload_merge,
          headers: { Authorization: auth.code },

          onUploadProgress: e => {
            // if (this.showfileupload[objIndex]["cancel"] === true) {
            //   this.showfileupload[objIndex]["canceltoken"] = source.cancel("Operation canceled by the user.");
            //   this.showfileupload[objIndex]["value"] = 500;
            //   this.showfileupload[objIndex]["status"] = "Operation canceled by the user.";
            //   this.showfileupload[objIndex]["color"] = "red";
            //   this.showfileupload[objIndex]["icon_status"] = "priority_high";
            // setTimeout(() => {
            //   resolve({
            //     status: "upload merge denied",
            //     msg: "",
            //   });
            // }, 2000);
            // } else {
            //   if ((e.loaded / e.total) * 100 >= 95) {
            //     this.showfileupload[objIndex]["value"] = 95;
            //   } else {
            //     this.showfileupload[objIndex]["value"] = parseInt((e.loaded / e.total) * 100);
            //   }
            // }
          }
        });
        if (result.data.status === "OK") {
          this.percentfileuploading = 100;
          this.showfileupload[_index]["value"] = 100;
          this.showfileupload[_index]["color"] = "success"
          this.showfileupload[_index]["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: "Upload File Success",
                type: "file"
              });
            }, 1500);
          });
        } else {
          this.percentfileuploading = 100;
          this.showfileupload[_index]["value"] = 100;
          this.showfileupload[_index]["color"] = "red"
          if (this.count_cancel === 1 || this.showfileupload[_index].checkcancelone === true) {
            this.showfileupload[_index]["status"] = {
              code: "cancel",
              message: "dragdrop.cancelupload",
              devMessage: "",
            };
          } else {
            this.showfileupload[_index]["status"] = {
              code: "fail",
              message: "dragdrop.cannotdetailupload",
              devMessage: err,
            };
          }
          this.closeSnackbar = true;
          this.btnClosedetail = true;
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({
                status: "ER",
                msg: result.data.errorMessage,
                type: "file"
              });
            }, 1500);
          });
        }
      } catch (error) {
        
      }
    },

    fn_check_payload_file(_index) {
      let folderid = "";
      for (let i = 0; i < this.showfileupload.length; i++) {
        if (this.showfileupload[i]["type"] === "folder") {
          console.log("this.showfileupload[_index].path", this.showfileupload[_index]["path"]);
          if (
            this.showfileupload[_index]["path"] === this.showfileupload[i]["path"]
          ) {
            folderid = this.showfileupload[i]["folderid"];
          }
        }
      }
      const formData = new FormData();
      formData.append(`account_id`, this.dataAccountId);
      formData.append("folder_id", folderid);
      formData.append(`file_duplicate`, this.showfileupload[_index]["file_duplicate"])
      formData.append(`file_name`, this.showfileupload[_index]["name"]);
      formData.append(`size_file`, this.showfileupload[_index]["size"]);
      formData.append(`file_part`, this.showfileupload[_index]["name"] + `.part_${this.showfileupload[_index]["numchunk"]}`);
      formData.append(`key`, this.showfileupload[_index]["chunkkey"]);
      if (this.$route.name === "directorysharefile") {
        formData.append("folder_form_share", "Y");
      }
      for (let i = 0; i < this.part_per_send; i++) {
        if (this.showfileupload[_index]["allchunk"][i + this.showfileupload[_index]["numchunk"] * this.part_per_send]) {
          formData.append(`blob`, this.showfileupload[_index]["allchunk"][i + this.showfileupload[_index]["numchunk"] ]);
        }
      }
      return new Promise((resolve) => {
        resolve({ status: "file", msg: formData });
      });
    },

    async fn_check_payload_file_merge(_index) {
      const formData = new FormData();
      let folderid = "";
      for (let i = 0; i < this.showfileupload.length; i++) {
        if (this.showfileupload[i]["type"] === "folder") {
          if (
            this.showfileupload[_index]["path"] === this.showfileupload[i]["path"]
          ) {
            folderid = this.showfileupload[i]["folderid"];
          }
        }
      }
      formData.append(`account_id`, this.dataAccountId);
      formData.append(`user_id`, this.dataUsername);
      formData.append("folder_id", folderid);
      formData.append(`file_duplicate`, this.showfileupload[_index]["file_duplicate"]);
      formData.append(`file_name`, this.showfileupload[_index]["name"]);
      formData.append(`remark`, "");
      formData.append(`len_file`, Math.ceil(this.showfileupload[_index]["allchunk"].length / this.part_per_send));
      formData.append(`key`, this.showfileupload[_index]["chunkkey"]);
      if (this.$route.name === "directorysharefile") {
        formData.append("folder_form_share", "Y");
      }
      return new Promise((resolve) => {
        resolve({ status: "file", msg: formData });
      });
    },

    generate_uuid(_index) {
      let myuuid = "";
      myuuid = uuidv4();
      this.showfileupload[_index]["chunkkey"] = myuuid;
    },

    async create_chunk(item) {
      var size = 52428800;
      var chunks = 0;
      chunks = Math.ceil(item.size / size);
      for (let i = 0; i < chunks; i++) {
        item.allchunk.push(
          item.slice(i * size, Math.min(i * size + size, item.size), item.type)
        );
      }
    },

    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png" ||
        parameter === "gif" ||
        parameter === "bmp"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
    detailuploadclick() {
      this.opendetailupload = true;
      // this.opensnackbar = false;
    },
    fn_endprocess() {
      this.datachunkwaiting = [];
      this.chunkwaiting = [];
      this.checkopenchunk = false;
      this.btnClosedetail = false;
      this.fulldisplay = false;
      this.minidisplay = false;
      this.rawfileupload = [];
      this.folderParent = [];
      this.currentpath = "";
      this.showfileupload = [];
      this.zerofile = true;
      this.listfileduplicate = [];
      // this.showupload = [];
      this.fileduplicate = "";
      this.openalertduplicatefile = false;
      this.listpathfolderduplicate = [];
      this.listfileinfolderduplicate = [];
      this.checkfirstloopchoice = false;
      this.numid = 0;
      // this.checkcurrentupload = false;
      this.$emit("closewithoutload");
      this.forceDestroyEvent(false)
    },

    fn_endprocess_success() {
      if (this.fulldisplay === false) {
        this.fulldisplay = false;
        this.minidisplay = false;
        this.rawfileupload = [];
        this.folderParent = [];
        this.currentpath = "";
        this.showfileupload = [];
        this.zerofile = true;
        this.listfileduplicate = [];
        // this.showupload = [];
        this.fileduplicate = "";
        this.openalertduplicatefile = false;
        this.listpathfolderduplicate = [];
        this.listfileinfolderduplicate = [];
        this.statusskip = false;
        this.checkfirstloopchoice = false;
        // this.checkcurrentupload = false;
        this.forceDestroyEvent(false)
        this.$emit("closeUpload");
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.loopcheckupload);
  },
};
</script>
